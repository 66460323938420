<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      CONFERÊNCIA MENSAL DE COLABORADORES POR PROJETO
    </v-card-title>
    <v-card-text>
      <div class="d-flex pt-0">
        <v-spacer></v-spacer>
        <div class="table-v-action-button mr-3 pt-1" @click="load()">
          <v-icon>mdi-refresh</v-icon>Atualizar
        </div>
        <div
          class="table-v-action-button mr-3 pt-1"
          @click="exportCsv(arrayToPrint)"
        >
          <v-icon>mdi-file-delimited-outline</v-icon>Exportar CSV
        </div>
        <div
          class="table-v-action-button mr-3 pt-1"
          @click="exportXlsx(arrayToPrint)"
        >
          <v-icon>mdi-file-excel-outline</v-icon>Exportar XLSX
        </div>
        <v-autocomplete
          v-model="funcionarioId"
          :items="colaboradores"
          item-value="id"
          item-text="nome"
          dense
          prefix="Colaborador:"
          placeholder="todos"
          prepend-icon="mdi-account-box-outline"
          hide-details
          clearable
          class="mb-n1 mt-0 mr-6"
        ></v-autocomplete>
        <monthly-filter
          class="mb-n1 mt-0 mr-3"
          style="max-width: 255px"
          one-month
          v-model="competencia"
          @change="load(); getProjects($event)"
        ></monthly-filter>
        <servico-select
          class="mb-n1 mt-0 mr-3"
          style="max-width: 255px"
          v-model="beneficio"
          @change="load()"
        ></servico-select>
      </div>
    </v-card-text>
    <v-data-table
      data-app
      :items="relatorioFormatado"
      :headers="cols"
      :footer-props="{
        'disable-items-per-page': false,
        'items-per-page-text': 'Registros por página',
        'items-per-page-options': [10, 50, 200, -1],
      }"
      height="calc(100vh - 315px)"
      fixed-header
    >
      <template v-slot:item="{ item, headers }">
        <tr>
          <td
            v-for="col in headers"
            v-bind:key="col.value"
            :class="{
              nowrap: col.nowrap,
              'table-v-tr':
                (col.isProject &&
                  item.Diferenca != 0 &&
                  !isClient) ||
                col.value == 'nomeColaborador' ||
                (col.value === 'Diferenca' && item.Diferenca > 0),
              'red--text text--lighten-1':
                col.value == 'Diferenca' && item.Diferenca < 0,
              'text-right': col.value != 'nomeColaborador',
            }"
            @click="onProjectClick(item, col, $event)"
            @contextmenu.stop="onProjectClick(item, col, $event)"
            @mouseover="changeHeaderColor(item, col)"
            @mouseleave="resetHeaders()"
          >
            {{ col.format ? col.format(item[col.value]) : item[col.value] }}
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="ajusteHorasDialog" persistent max-width="450">
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-clock-alert-outline</v-icon>Ajuste de
          Horas
          <v-btn
            @click="ajusteHorasDialog = false"
            icon
            style="position: absolute; right: 5px; top: 5px"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Deseja
          <span v-if="ehAdicaoHoras" class="font-weight-medium blue--text text--lighten-1">
            adicionar as horas
          </span>
          <span v-else class="font-weight-medium red--text text--lighten-1">
            subtrair as horas
          </span>
          lançadas para o colaborador
          <span class="font-weight-medium">
            '{{ itemToChange.colaboradorNome }}'
          </span>
          <template v-if="!ehAdicaoHoras">
            no projeto
            <span class="font-weight-medium">
              '{{ selectedProject.titulo }}'
            </span>
          </template>
          em:
          <v-form ref="form" class="pt-4" v-model="ajusteHorasDialogValid">
            <v-autocomplete
              v-if="ehAdicaoHoras"
              v-model="itemToChange.projetoId"
              placeholder="Projeto"
              prepend-inner-icon="mdi-clipboard-text"
              item-text="titulo"
              item-value="id"
              dense
              outlined
              clearable
              :disabled="!projetos || projetos.length < 1"
              :items="projetos"
              :rules="[requiredRule]"
              @change="getAtividades()"
            ></v-autocomplete>
            <v-autocomplete
              v-model="itemToChange.atividadeId"
              prepend-inner-icon="mdi-format-list-bulleted-type"
              item-text="atividade"
              item-value="id"
              dense
              outlined
              clearable
              :placeholder="atividadePlaceholder"
              :disabled="atividadeDisabled"
              :items="atividades"
              :rules="atividadeDisabled ? [] : [requiredRule]"
            ></v-autocomplete>
            <v-text-field
              v-model="itemToChange.subtrairHoras"
              placeholder="0,00"
              :prepend-inner-icon="textFieldPrependIcon"
              dense
              outlined
              type="number"
              min="0"
              :rules="[maxAdjustRule]"
            ></v-text-field>
            <v-textarea
              v-if="ehAdicaoHoras"
              v-model="itemToChange.comentario"
              placeholder="Comentário"
              prepend-inner-icon="mdi-text-long"
              outlined
              dense
              persistent
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            dark
            depressed
            @click="ajusteHorasDialog = false"
            >Não</v-btn
          >
          <v-btn
            color="primary"
            :disabled="disableAlocarHorasBtn"
            dark
            depressed
            @click="ajusteHoras()"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import _ from "lodash";
import { mapGetters } from "vuex";
export default {
  directives: { mask: mask },
  components: {
    "monthly-filter": () => import("@/components/monthly-filter.vue"),
    "servico-select": () => import("@/components/servico-select.vue"),
  },
  computed: {
    ...mapGetters(["clientId"]),
    atividadeDisabled: function () {
      return !this.itemToChange.projetoId || !this.atividades || this.atividades.length < 1;
    },
    atividadePlaceholder: function () {
      if (!this.itemToChange?.projetoId) {
        return 'Selecione um projeto para ver atividades';
      }

      if (this.atividades?.length < 1) {
        return 'Nenhuma atividade disponível';
      }

      return 'Atividade';
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    resource: function () {
      return this.apiResource(
        `/v1/timesheet/${this.clientId}/conferencia`
      );
    },
    resourceAjusteHoras: function () {
      return this.apiResource(
        `/v1/timesheet/${this.clientId}/${this.ehAdicaoHoras ? 'adicionaHoras' : 'ajusteHoras'}`
      );
    },
    resourceAtividades: function () {
      return this.apiResource(`/v1/timesheet/${this.clientId}/atividades/selecao?projetoId=${this.itemToChange.projetoId}&funcionarioId=${this.itemToChange.colaboradorId}&competencia=${this.competencia}`);
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao`);
    },
    relatorioFormatado: function () {
      return this.relatorio
        .filter(
          (r) => !this.funcionarioId || r.colaborador.id == this.funcionarioId
        )
        .map((row) => {
          row["nomeColaborador"] = row.colaborador.nome;
          row["idColaborador"] = row.colaborador.id;

          row.HorasProjeto.forEach((p) => {
            var coluna = this.colunas.find((c) => c.value == p.projetoId);
            if (!coluna) {
              this.colunas.push({
                text: p.titulo,
                value: p.projetoId,
                sortable: false,
                isProject: true,
                class: `vuetify-table-header${
                  this.isClient ? "-cli" : ""
                }`,
                align: "right",
              });
            }
            row[p.projetoId] = p.horasProjeto;
          });

          this.colunas.forEach((c) => {
            if (c.data) {
              row[`${c.value}_value`] = row[c.value];
              row[c.value] = this.opts[c.data][row[c.value]];
            }
          });
          return row;
        });
    },
    arrayToPrint: function () {
      var array = [];
      array.push(this.colunas.map((c) => c.text));
      this.relatorioFormatado.forEach((row) => {
        array.push(
          this.colunas.map((c) =>
            c.format ? c.format(row[c.value]) : row[c.value]
          )
        );
      });
      return array;
    },
    colaboradores: function () {
      return _.uniqBy(
        this.relatorio.map((m) => ({
          id: m.colaborador.id,
          nome: m.colaborador.nome,
        })),
        "id"
      );
    },
    cols: function () {
      const format = this.isClient
        ? this.$options.filters.toTime
        : this.$options.filters.toDoubleComplete;
      return this.colunas.map((col) => ({ ...col, format }));
    },
    disableAlocarHorasBtn: function () {
      return (
        !this.itemToChange.subtrairHoras ||
        this.itemToChange.subtrairHoras < 0 ||
        !this.ajusteHorasDialogValid
      );
    },
    ehAdicaoHoras: function () {
      return this.itemToChange.diferencaHoras > 0;
    },
    selectedProject: function () {
      return this.projetos.find(({ id }) => id === this.itemToChange.projetoId) || {};
    },
    textFieldPrependIcon: function () {
      return this.ehAdicaoHoras ? 'mdi-clock-plus-outline' : 'mdi-clock-minus-outline';
    },
  },
  created: function () {
    if (this.$route.query.funcionarioId) {
      this.funcionarioId = parseInt(this.$route.query.funcionarioId, 10);
    }
  },
  methods: {
    getProjects: function (competencia) {
      const ano = competencia.substring(0, 4);
      if (this.lastProjectYear != ano) {
        this.lastProjectYear = ano;
        const query = `ano=${ano}`;
        this.resourceProjetos.get({ query }).then((response) => {
          this.projetos = response.projetos;
        });
      }
    },
    load: function () {
      if (!this.competencia || !this.beneficio) {
        return;
      }

      this.resetHeaders();
      this.resource
        .get({
          query: `competencia=${this.competencia}&trabalho=${this.beneficio}`,
        })
        .then((response) => {
          if (!response.error) {
            this.relatorio = response[0];
          }
        }).catch(() => {
          this.relatorio = [];
        });
    },
    onProjectClick: function (item, col, e) {
      e.preventDefault();
      const isDifference = col.value === 'Diferenca';
      if (
        !this.isClient &&
          ((isDifference && item.Diferenca > 0) ||
          (col.isProject && item.Diferenca != 0))
      ) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        this.itemToChange.colaboradorId = item.colaborador.id;
        this.itemToChange.colaboradorNome = item.colaborador.nome;
        this.itemToChange.projetoId = !isDifference ? col.value : null;
        this.itemToChange.diferencaHoras = item.Diferenca;
        this.itemToChange.subtrairHoras = null;
        this.itemToChange.atividadeId = null;
        this.itemToChange.comentario = '';

        if (this.itemToChange.projetoId) {
          this.getAtividades();
        }

        this.ajusteHorasDialog = true;
      } else if (col.value == "nomeColaborador") {
        this.actContext(item, e);
      }
    },
    ajusteHoras: function () {
      if (this.ajusteHorasDialogValid) {
        this.resourceAjusteHoras
          .save({
            projetoId: this.itemToChange.projetoId,
            colaboradorId: this.itemToChange.colaboradorId,
            competencia: this.competencia,
            horas: this.itemToChange.subtrairHoras,
            atividadeId: this.itemToChange.atividadeId,
            comentario: this.itemToChange.comentario,
            servico: this.beneficio,
          })
          .then(() => {
            this.ajusteHorasDialog = false;
            this.load();
          });
      }
    },
    resetHeaders: function () {
      this.colunas.forEach((c) => {
        c.class = `vuetify-table-header${this.isClient ? "-cli" : ""}`;
      });
    },
    changeHeaderColor: function (item, col) {
      if (
        !this.isClient &&
          ((col.value === 'Diferenca' && item.Diferenca > 0) ||
          (col.isProject && item.Diferenca != 0))
      ) {
        this.colunas.forEach((h) => {
          h.class =
            col.value == h.value
              ? "mouseover-header"
              : `vuetify-table-header${this.isClient ? "-cli" : ""}`;
        });
      }
    },
    actContext: function (v, e) {
      let menu = [
        {
          name: "Cadastro",
          limit: 1,
          show: this.userHasAccess("Colaboradores.resource.update"),
          cb: ({ idColaborador }) => {
            const routeData = this.$router.resolve({
              name: "edicao-colaborador",
              params: { colaboradorId: idColaborador },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "De->Para",
          limit: 1,
          show: this.userHasAccess("Colaboradores.resource.depara"),
          cb: ({ idColaborador }) => {
            const routeData = this.$router.resolve({
              name: "de-para-colaboradores",
              params: { colaboradorId: idColaborador },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "sep",
          // Só exibe se houver algum item antes e depois da separação
          show: {
            get() {
              return menu.slice(0, 2).some(({ show }) => show) && menu.slice(3).some(({ show }) => show);
            }
          },
        },
        {
          name: "Alocação de Horas",
          limit: 1,
          show: this.userHasAccess("Timesheet.alocacaoDeHoras.index"),
          cb: ({ idColaborador }) => {
            const routeData = this.$router.resolve({
              name: "timesheets",
              query: { funcionarioId: idColaborador },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "Folha de Pagamento",
          limit: 1,
          show: this.userHasAccess("Colaboradores.folhaDePagamento.index"),
          cb: ({ idColaborador }) => {
            const routeData = this.$router.resolve({
              name: "folha-pagamento-colaborador",
              params: { colaboradorId: idColaborador },
            });
            window.open(routeData.href, "_blank");
          },
        },
        {
          name: "Cálculo do Dispêndio",
          limit: 1,
          show: !this.isClient,
          cb: ({ idColaborador }) => {
            const routeData = this.$router.resolve({
              name: "dispendio-rh",
              query: { colaboradorId: idColaborador },
            });
            window.open(routeData.href, "_blank");
          },
        }
      ];

      this.showContext({
        left: e.clientX,
        top: e.clientY,
        val: v,
        menu: menu.filter(({ show }) => show),
      });
    },
    requiredRule: function (value) {
      return !!value || 'Obrigatório';
    },
    maxAdjustRule: function (value) {
      const diferencaHoras = Math.abs(this.itemToChange.diferencaHoras * 1);
      const projetoHoras = this.selectedProject.projetoHoras * 1;
      const v = value * 1;
      const maxValue =
        !this.ehAdicaoHoras && projetoHoras < diferencaHoras ? projetoHoras : diferencaHoras;

      return (
        !value ||
        v <= maxValue ||
        `Valor máximo ${this.$options.filters.toDoubleComplete(maxValue)}.`
      );
    },
    getAtividades: function () {
      this.resourceAtividades.get().then((response) => this.atividades = response.sort((a, b) => a.atividade.localeCompare(b.atividade)));
    }
  },
  data: function () {
    return {
      funcionarioId: null,
      lastProjectYear: null,
      itemToChange: {
        colaboradorId: null,
        colaboradorNome: null,
        projetoId: null,
        diferencaHoras: null,
        subtrairHoras: null,
        atividadeId: null,
        comentario: '',
      },
      ajusteHorasDialog: false,
      ajusteHorasDialogValid: true,
      competencia: null,
      beneficio: null,
      relatorio: [],
      projetos: [],
      atividades: [],
      colunas: [
        {
          text: "Nome",
          value: "nomeColaborador",
        },
        {
          text: "Total Timesheets (importados)",
          value: "HorasTotal",
          align: "right",
        },
        {
          text: "Horas em P&D (calculado)",
          value: "horas_ped",
          align: "right",
        },
        {
          text: "Horas Trabalhadas",
          value: "horasTrabalhadas",
          align: "right",
        },
        {
          text: "Diferença",
          value: "Diferenca",
          align: "right",
        },
      ],
    };
  },
  watch: {
    clientId: function () {
      this.load();
    },
  }
};
</script>

<style scoped lang="scss">
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(#1c1c44, 0.75) !important;
  color: #ffffff !important;
}

.v-data-table::v-deep {
  table {
    table-layout: fixed;
  }

  th {
    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    i {
      position: absolute;
      top: 50%;
      bottom: 50%;
      right: 0px;
    }

    // Mostrar header ocultado se não for as primeiras cinco colunas
    &:not(:nth-child(-n + 5)):hover {
      z-index: 3;

      span {
        background-color: #bedefc !important;
        display: inline;
        padding: 12px 0;
        white-space: nowrap;
      }
    }
  }

  th,
  td {
    width: 165px;

    &:nth-child(-n + 5) {
      position: sticky;
      background-color: #fff;
      padding: 0 8px;
    }

    &:nth-child(1) {
      padding-left: 16px;
      padding-right: 0;
      left: 0;
      width: 130px;
    }

    &:nth-child(2) {
      padding-left: 0;
      left: 130px;
      width: 130px;
    }

    &:nth-child(3) {
      left: 260px;
      width: 110px;
    }

    &:nth-child(4) {
      left: 370px;
      width: 102px;
    }

    &:nth-child(5) {
      left: 472px;
      width: 97px;
    }
  }

  th:nth-child(-n + 5) {
    z-index: 3;
  }

  // @for $i from 1 through 5 {
  //   th,
  //   td {
  //     &:nth-child(#{$i}) {
  //       left: calc((#{$i} - 1) * 107px);
  //     }
  //   }
  // }
}

.mouseover-header {
  background: #f2f9ff !important;
}

.mouseover-header span {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  mix-blend-mode: normal;
  color: #3e3e3f;
}
</style>
